<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">Broadcast</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <router-link :to="{ name: 'create-broadcast', query: { t: new Date().getTime() } }">
          <v-btn
            :disabled="pageLoading"
            :loading="pageLoading"
            class="white--text mt-1"
            depressed
            color="blue darken-4"
            tile
          >
            Create Broadcast
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-simple-table fixed-header height="72vh" class="bt-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="80">Action</th>
                <th width="200">Name</th>
                <th width="50">Template Name</th>
                <th width="50">Category</th>
                <th width="50">Segment Name</th>
                <th width="50">Broadcast Type</th>
                <th width="300">Schedule Detail</th>
                <th width="80">Created At</th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="brodcastList.length">
                <tr
                  v-for="(row, bkey) in brodcastList"
                  :key="bkey"
                  @click="goToSegmentDetail(row.id)"
                >
                  <td width="50">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn
                            v-on:click.stop.prevent="deleteConfirm(row.name, row.id)"
                            depressed
                            fab
                            dark
                            x-small
                            @click="goBrodcastupdate(row.id)"
                            color="blue darken-4 white--text"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                  <td width="100">
                    <ValueTemplate v-model="row.name" title="Name"></ValueTemplate>
                  </td>
                  <td width="250">
                    <ValueTemplate
                      class="text-capitalize"
                      v-model="row.template_name"
                      title="Template Name"
                    ></ValueTemplate>
                  </td>
                  <td width="200">
                    <ValueTemplate
                      class="text-capitalize"
                      v-model="row.category"
                      title="Category"
                    ></ValueTemplate>
                  </td>
                  <td width="250">
                    <ValueTemplate
                      class="text-capitalize"
                      v-model="row.segment_name"
                      title="Segment Name"
                    ></ValueTemplate>
                  </td>
                  <td width="250">
                    <ValueTemplate
                      class="text-capitalize"
                      v-model="row.broadcast_types"
                      title="Broadcast Type"
                    ></ValueTemplate>
                  </td>
                  <td width="200">
                    <div>
                      <span class="font-level-3-bold">Schedule Type: </span>
                      <span class="text-capitalize">{{ row.schedule_type }}</span>
                    </div>
                    <div>
                      <!--v-if="row.schedule_type != 'now' &&  row.schedule_datetime"-->
                      <span class="font-level-3-bold">Schedule Time: </span>
                      <ValueTemplate
                        v-model="row.schedule_datetime"
                        datetime
                        title="Schedule Time"
                      ></ValueTemplate>
                    </div>
                  </td>

                  <td width="200">
                    <ValueTemplate
                      v-model="row.added_at"
                      datetime
                      title="Created At"
                    ></ValueTemplate>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no segment at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 9" :key="idr">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <template>
          <v-layout class="light-border-top mt-4">
            <v-flex md6>
              <label class="btx-label p-4">{{ showingString }}</label>
            </v-flex>
            <v-flex md6>
              <v-pagination
                color="blue darken-4"
                v-model="currentPage"
                :length="totalPages"
                :total-visible="7"
                v-on:input="getSegments()"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </template>
      </v-col>
    </v-row>

    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getSegments()"
    />
    <Dialog :dialog.sync="deleteDialog" :dialogWidth="750">
      <template v-slot:title> Delete Segments</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              <span class="text-capitalize">Segments </span>
              <b class="red--text text--lighten-1">{{ deleteText }}</b> will be deleted forever and
              cannot be retrieved later.<br />Are you sure about deleting it?
            </p>
          </v-col>
          <v-col md="12" class="pb-0 my-auto">
            <em>Note: All transactions of this segment will also be deleted.</em>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="pageLoading"
          :disabled="pageLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteSegment()"
        >
          Yes! Delete
        </v-btn>
        <v-btn depressed tile :disabled="pageLoading" v-on:click="deleteDialog = false">
          No, Close
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import CreateSegment from "@/view/module/marketing/CreateSegment";
import ValueTemplate from "@/view/components/ValueTemplate";
// import Chip from "@/view/components/Chip";
import { GET_BROADCASTS } from "@/core/lib/marketing.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CHANGE_SEGMENTS_STATUS, DELETE_SEGMENTS } from "@/core/lib/marketing.lib";
import Dialog from "@/view/components/Dialog";
export default {
  name: "customer-create",
  title: "Create Customer",
  components: { ValueTemplate, CreateSegment, Dialog },
  data() {
    return {
      pageLoading: false,
      generateSegmentDialog: false,
      deleteDialog: false,
      deleteText: null,
      deleteId: null,
      showingString: null,
      currentPage: 1,
      loadPagination: false,
      totalPages: null,
      deleteEndpoint: null,
      brodcastList: [],
    };
  },
  computed: {
    // 	product_list: () => {
    // 	return function (id) {
    // 		let _productList = cloneDeep(this.productList);
    // 		const newArray = filter(_productList, (row) => {
    // 			if (row.value != id) {
    // 				if (!find(this.line_items, (inner_row) => inner_row.product == row.id)) {
    // 					return row;
    // 				}
    // 			} else {
    // 				return row;
    // 			}
    // 		});
    // 		return newArray;
    // 	};
    // },
  },

  methods: {
    goToSegmentDetail(id) {
      this.$router.push({
        name: "broadcast-detail",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    goBrodcastupdate(id) {
      this.$router.push({
        name: "update-broadcast",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },
    async getSegments() {
      const _this = this;
      this.pageLoading = true;
      try {
        const { rows, showing_string, totalPages } = await GET_BROADCASTS(
          this.currentPage
          //this.per_page
        );
        this.brodcastList = rows;
        this.showingString = showing_string;
        this.totalPages = totalPages;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    async segmentsDeleteSuccess() {
      await this.getSegments();
      this.deleteDialog = false;
    },
    async deleteSegment() {
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      this.pageLoading = true;
      try {
        await DELETE_SEGMENTS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment delete successfully." },
        ]);
        await this.getSegments();
        this.deleteDialog = false;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteConfirm(name, id) {
      this.deleteText = name;
      this.deleteId = id;
      this.deleteDialog = true;
    },
    async changeSegmentStatus(status, id) {
      const _this = this;
      const params = {
        action: status == 1 ? "active" : "inactive",
        selected: [id],
      };
      this.pageLoading = true;
      try {
        await CHANGE_SEGMENTS_STATUS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment Status Change Successfully." },
        ]);
        await this.getSegments();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    await this.getSegments();
  },
};
</script>
